body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: black;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#root {
  height: 100vh;
  width: 100vw;
}

.introDiv {
  text-align: center;
}

.loginButton {
  border: 1px solid white;
  border-radius: 7px;
  background-color: rgb(97, 97, 97);
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin: 5px;
}

.loginButton:hover {
  border: 2px solid white;
  margin: 4px;
  border-radius: 7px;
  background-color: rgb(109, 109, 109);
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.icon {
  color: white;
}

.playPauseButton,
.playPauseButton:focus {
  border: 0px;
  outline: none;
  margin: 10px;
  cursor: pointer;
  background-color: transparent;
}

.loginButtonContents {
  display: flex;
  align-items: center;
}

.loginButtonText {
  margin-left: 10px;
  text-align: left;
  color: white;
}

.bottomText {
  font-size: 12pt;
  margin-top: 50px;
}

.fillParent {
  height: 100%;
  width: 100%;
}

.photoImg {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  object-fit: contain;
}

.videoPlayer {
  height: calc(100% - 50px);
  width: 100%;
  flex-shrink: 0;
  object-fit: contain;
}

.photoImgDivForIE {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.centeredText {
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}

.bottomBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: rgba(54, 54, 54, 0.8);
  display: flex;
  align-items: center;
  flex: 1 auto;
}

.itemDate {
  margin: 20px;
}

.ages {
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.createAgeContainer {
  display: flex;
  margin: 10px;
}

.creatureAge {
  margin-left: 10px;
}

.previousButton,
.previousButton:active,
.previousButton:focus {
  height: calc(100% - 150px);
  width: 25%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}

.nextButton,
.nextButton:active,
.nextButton:focus {
  height: 100%;
  width: 25%;
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 0;
  background: transparent;
  border: none;
  outline: none;
}
